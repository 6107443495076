<template>
  <div>
    <div class="subheader pb-5 subheader-transparent" id="kt_subheader">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap py-0">
        <div class="d-flex align-items-center flex-wrap mr-2">
          <h5 class="text-dark font-weight-bolder mt-2 mb-2 mr-5">{{ $t('tariff.title') }}</h5>
          <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bolder" id="kt_subheader_total">{{ $t('tariff.count', {count: totalCount}) }}</span>
          </div>
        </div>
        <div if="list.locationsFilterList > 0" class="d-flex align-items-center">
          <div class="form-group d-flex align-items-center m-0">
            <label class="font-weight-bolder text-muted m-0 mr-3">{{ $t('tariff.location') }}</label>
            <select v-model="filter.location_id" class="form-control form-control-sm" style="min-width: 200px">
              <option selected value="">{{ $t('tariff.all') }}</option>
              <option v-for="location in locationsFilterList" :key="location.id" :value="location.id">{{location.name}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loaded" class="d-flex flex-column-fluid">
      <div class="container py-0">
        <div class="row">
          <div v-for="item in list" class="col-md-4">
            <div class="card card-custom gutter-b card-stretch">
              <div class="card-header pt-5">
                <div class="card-label font-size-h5 font-weight-bolder">
                  <span class="lines-count lines-count-1">{{item.nameFormated}}</span>
                  <div class="font-size-sm text-muted">
                    <b-button class="btn btn-icon btn-xs btn-circle bg-transparent border-0 w-auto h-auto mr-1 p-0" :id="'t' + item.id">
                      <font-awesome-icon icon="info-circle" class="text-info" />
                    </b-button>
                    <b-tooltip :target="'t' + item.id" triggers="hover" placement="bottomleft" :no-fade=true>
                      <small class="text-muted font-weight-bolder text-left"
                      >{{ $t('tariff.tariff_location_all') }}</small><br>
                      <ul v-for="location in getLocationListFromIds(item.location_ids)" class="list-unstyled text-left mt-1 mb-0">
                        <li>{{location.name}}</li>
                      </ul>
                    </b-tooltip>
                    {{ $t('tariff.locations') }}
                  </div>
                </div>
              </div>
              <div class="card-body pt-4 d-flex flex-column justify-content-between">
                <div class="tariff-description" v-html="item.descr">
                </div>
                <div class="mt-5">
                  <span v-if="item.rate"
                        class="font-size-xs font-weight-bolder text-muted">{{ $t('tariff.vat') }}</span>
                  <div v-if="item.rate"
                       class="font-weight-bolder font-size-h3"
                  >{{item.rate.total_cost}} <span v-if="item.rate.currency">{{item.rate.currency.symbol}}</span></div>
                  <div v-if="item.rate"
                       class="font-weight-bolder text-secondary"
                  >{{ $t('tariff.to_all', {q: item.rate.quantity}) }} <span v-if="item.rate.dimension">{{item.rate.dimension.nameLang}}</span></div>
                  <div class="mt-5">
                    <router-link :to="`/services/purchase-tariff/${item.id}`"
                                 class="btn btn-primary btn-block font-weight-bolder">{{ $t('tariff.buy') }}</router-link>
                  </div>
                </div>
              </div>
              <!--end::Body-->
            </div>
          </div>
          <div class="container py-0">
            <div class="row">
              <div class="d-flex flex-wrap pt-5 flex-column-fluid">
                <div v-if="totalCount && (maxPage > 1)" class=" mx-auto center-block">
                  <a v-if="filter.page > 1" @click="filter.page = 1"
                     class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-back icon-xs"></i></a>

                  <a v-if="filter.page > 1" @click="filter.page = filter.page - 1"
                     class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">{{filter.page - 1}}</a>
                  <a class="btn btn-icon btn-sm border-0 btn-light btn-hover-primary active mr-2 my-1">{{filter.page}}</a>
                  <a v-if="filter.page < totalCount / filter['per-page']" @click="filter.page = filter.page + 1"
                     class="btn btn-icon btn-sm border-0 btn-light mr-2 my-1">{{filter.page + 1}}</a>

                  <a v-if="filter.page < maxPage" @click="filter.page = maxPage"
                     class="btn btn-icon btn-sm btn-light mr-2 my-1"><i class="ki ki-bold-arrow-next icon-xs"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loaded" class="d-flex flex-column-fluid">
      <div class="container py-0">
        <div class="row">
          <div v-for="index in 3" class="col-md-4">
            <div class="card card-custom gutter-b card-stretch">
              <div class="card-body pt-4 d-flex flex-column justify-content-between">
                <PuSkeleton height="20px"></PuSkeleton>
                <PuSkeleton :count="2" width="80%"></PuSkeleton>
                <br>
                <PuSkeleton width="80%"></PuSkeleton>
                <PuSkeleton :count="3"></PuSkeleton>
                <br>
                <PuSkeleton height="20px"></PuSkeleton>
                <PuSkeleton :count="1" width="80%"></PuSkeleton>
              </div>
              <!--end::Body-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapState, mapActions} from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapState({
      // packages: state => state.dashboard.packages,
    }),
    ...mapGetters({
      list: 'tariffServices/list',
      page: 'tariffServices/page',
      perPage: 'tariffServices/perPage',
      totalCount: 'tariffServices/totalCount',
      locations: 'tariffServices/locations',
      loaded: 'tariffServices/loaded',
      locationsFilterList: 'tariffServices/locationsFilterList',
    }),
  },
  data: () => {
    return {
      maxPage: 1,
      filter: {
        sort: [],
        location_id: '',
        status: 1,
        page: 1,
        'per-page': 21,
      }
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    items_count: function () {
      this.maxPage = Math.ceil(this.totalCount / this.perPage)
      return this.items ? Object.keys(this.items).length : 0;
    },
    fetch() {
      // for (let i in this.columns) {
      //   switch(this.columns[i].sort) {
      //     case 1:
      //       this.filter.sort = this.columns[i].key
      //       break;
      //     case 2:
      //       this.filter.sort = '-'+this.columns[i].key
      //       break;
      //   }
      // }
      let key = 'coworkingId_' + localStorage.getItem('currentUserId');
      let coworkingId = localStorage.getItem(key) || '';
      if (coworkingId) this.filter.coworking = coworkingId;
      this.$store.dispatch('tariffServices/GetData', this.filter).then(() => {
        this.items_count();
      });
      this.$store.dispatch('tariffServices/GetLocation', this.filter);
    },
    getLocationListFromIds(ids) {
      if (!ids) return [];
      let locations = []
      for (let i in this.locations) {
        if (ids.includes(this.locations[i].id)) {
          locations.push(this.locations[i])
        }
      }
      return locations
    }
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
      },
      deep: true,
    },
    list: {
      handler() {
        this.items_count()
      },
      deep: true,
    },
  },
}
</script>


<style>
.tooltip {
  opacity: 1!important;
}
</style>
